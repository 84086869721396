import styled from '@emotion/styled';
import media from 'utils/mediaqueries';

export const BurgerStyled = styled('div')(
  {
    position: 'relative',
    flex: 'none',
    cursor: 'pointer',
    pointerEvents: 'all',
    padding: '12px 6px',

    '&:focus, &:active': {
      WebkitTapHighlightColor: 'transparent',
    },
  },
  (props) => ({
    color: props.theme.colorPrimary, // 🏳
  }),
  // Overriding default color per page type.
  (props) =>
    props.pageType !== 'productsNl' && {
      color: props.theme.colorTertiary,
    },
  (props) =>
    props.active && {
      [media(0, 0, 'landscape')]: {
        color: props.theme.colorPrimary,
      },
    },
);

export const BurgerInnerStyled = styled('div')({
  position: 'relative',
  width: 25,
  height: 16,
});

// Normal state (burger)
export const BurgerBarStyled = styled('span')(
  {
    pointerEvents: 'none',
    position: 'absolute',
    display: 'block',
    content: '""',
    width: '100%',
    height: 2,
    backgroundColor: 'currentColor',
    transformOrigin: '50% 50%',

    // Top bar
    '&:nth-of-type(1)': {
      top: 0,
    },

    // Top bar
    '&:nth-of-type(2)': {
      opacity: 1,
      top: 7,
    },

    // Bottom bar
    '&:nth-of-type(3)': {
      top: 14,
    },
  },
  // Active state (cross)
  (props) =>
    props.active && {
      '&:nth-of-type(1)': {
        top: 6,
        transform: 'rotate(45deg)',
      },

      // Middle bar
      '&:nth-of-type(2)': {
        opacity: 0,
      },

      // Bottom bar
      '&:nth-of-type(3)': {
        top: 6,
        transform: 'rotate(-45deg)',
      },
    },
);
