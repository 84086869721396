import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import { useLockBodyScroll } from 'utils/hooks/useLockBodyScroll';

import Tile from './Tile';
import {
  MenuOverlayStyled,
  MenuStyled,
  MenuBgStyled,
  MainItemsStyled,
  MainItemStyled,
  HighlightItemsStyled,
  MetaItemsStyled,
  LinkStyled,
} from './MenuStyled';

function Menu({
  itemsMain,
  itemsMeta,
  itemsHightlight,
  menuOpen,
  onMenuItemClick,
  onMenuBgClick,
}) {
  // Call hook to lock body scroll
  useLockBodyScroll(menuOpen);

  return (
    <MenuOverlayStyled menuOpen={menuOpen}>
      <MenuBgStyled menuOpen={menuOpen} onClick={onMenuBgClick} />
      <MenuStyled menuOpen={menuOpen}>
        {itemsMain.length > 0 && (
          <MainItemsStyled>
            {itemsMain.map((item) => (
              <MainItemStyled key={`menu-main-item-${item.id}`}>
                <Tile
                  active={menuOpen}
                  image={item.menuItemImage?.[0]}
                  title={item.menuItemTitle}
                  onClick={onMenuItemClick}
                  to={
                    // either use full uri from entry or get normal url
                    item.menuItemLink?.entry
                      ? item.menuItemLink?.entry?.fullUri
                      : item.menuItemLink?.url
                  }
                  target={item.menuItemLink?.target || undefined}
                />
              </MainItemStyled>
            ))}
          </MainItemsStyled>
        )}
        {(itemsMeta.length > 0 || itemsHightlight.length > 0) && (
          <MetaItemsStyled>
            {itemsMeta.map((item) => (
              <LinkStyled
                key={`menu-meta-item-${item.id}`}
                to={
                  // either use full uri from entry or get normal url
                  item.menuItemLink?.entry
                    ? item.menuItemLink?.entry?.fullUri
                    : item.menuItemLink?.url
                }
                onClick={onMenuItemClick}
                target={item.menuItemLink?.target || undefined}
              >
                {item.menuItemTitle}
              </LinkStyled>
            ))}

            {itemsHightlight.length > 0 && (
              <HighlightItemsStyled>
                {itemsHightlight.map((item) => (
                  <LinkStyled
                    key={`menu-highlight-item-${item.id}`}
                    to={
                      // either use full uri from entry or get normal url
                      item.menuItemLink?.entry
                        ? item.menuItemLink?.entry?.fullUri
                        : item.menuItemLink?.url
                    }
                    onClick={onMenuItemClick}
                    target={item.menuItemLink?.target || undefined}
                    hightlight
                  >
                    {item.menuItemTitle}
                  </LinkStyled>
                ))}
              </HighlightItemsStyled>
            )}
          </MetaItemsStyled>
        )}
      </MenuStyled>
    </MenuOverlayStyled>
  );
}

Menu.propTypes = {
  menuOpen: PropTypes.bool,
  onMenuBgClick: PropTypes.func.isRequired,
  onMenuItemClick: PropTypes.func.isRequired,
  itemsMain: PropTypes.array,
  itemsMeta: PropTypes.array,
  itemsHightlight: PropTypes.array,
};

Menu.defaultProps = {
  menuOpen: false,
  itemsMain: [],
  itemsMeta: [],
  itemsHightlight: [],
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query MenuQuery {
        craft {
          globals(siteId: 8) {
            globalMenu {
              globalMenuMainNav {
                ... on Craft_GlobalMenuMainNavMenuItem {
                  id
                  menuItemTitle
                  menuItemLink {
                    entry {
                      id
                      fullUri
                    }
                    url
                    target
                  }
                  menuItemImage {
                    id
                    url
                    ...CraftImageFluid
                  }
                }
              }
              globalMenuMeta {
                ... on Craft_GlobalMenuMetaMenuItem {
                  id
                  menuItemTitle
                  menuItemLink {
                    entry {
                      id
                      fullUri
                    }
                    url
                    target
                  }
                }
              }
              globalMenuHighlight {
                ... on Craft_GlobalMenuHighlightMenuItem {
                  id
                  menuItemTitle
                  menuItemLink {
                    entry {
                      id
                      fullUri
                    }
                    url
                    target
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <Menu
        itemsMain={
          data?.craft?.globals?.globalMenu?.globalMenuMainNav || undefined
        }
        itemsMeta={
          data?.craft?.globals?.globalMenu?.globalMenuMeta || undefined
        }
        itemsHightlight={
          data?.craft?.globals?.globalMenu?.globalMenuHighlight || undefined
        }
        {...props}
      />
    )}
  />
);
