import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'emotion-theming';

import themeDefault from 'components/common/Layout/themes/default';
import themeAlternative from 'components/common/Layout/themes/alternative';
import themeTransparent from 'components/common/Layout/themes/transparent';
import themeWhite from 'components/common/Layout/themes/white';

export default function SwitchTheme({ name, children }) {
  const themeType = {
    default: themeDefault,
    alternative: themeAlternative,
    transparent: themeTransparent,
    white: themeWhite,
  };

  // Do not set default theme as fallback otherwise the inheritance wont work, simply throw
  // an error and use theming correct instead of polyfilling. So getting errors is actually right
  // and you should provide the correct theme instead. ~DH
  /* if (!(name in themeType)) {
    console.log(`This theme does not exist!: ${name}`);
  } */

  // Anyway we need to push an empty object to let e.g. a button component within the cms create an 'undefined'
  // field which lets react use the '<Button>' component in default mode choosing the right theming out of
  // the box. ~DH
  const theme = name in themeType ? themeType[name] : {};

  return (
    <ThemeProvider theme={theme}>
      <>{children}</>
    </ThemeProvider>
  );
}

SwitchTheme.propTypes = {
  name: PropTypes.string,
};

SwitchTheme.defaultProps = {
  name: 'default',
};
