import React from 'react';
import GatsbyImage from 'gatsby-image';
import { fluid as getFluidProps } from './sharpAdapter';

/**
 * Works like fluid image (fixed not supported yet) from gatsby plugin sharp and
 * gatsby image.
 * Use all gatsby image props normally.
 * Supports maxWidth / maxHeight from gatsby plugin sharp with the args prop.
 *
 * See:
 *  - https://github.com/gatsbyjs/gatsby/tree/master/packages/gatsby-plugin-sharp#fluid
 *  - https://github.com/gatsbyjs/gatsby/tree/master/packages/gatsby-image#gatsby-image-props
 */

export default function Image({
  fluid,
  args,
  title,
  altText,
  imgStyle = {},
  objectFit = 'cover',
  objectPosition = '50% 50%',
  aspectRatio,
  ...rest
}) {
  const _fluid =
    fluid && fluid.width
      ? getFluidProps(fluid, args, { aspectRatio })
      : undefined;

  if (!_fluid) {
    console.warn('Image misconfigured.');
    return null;
  }

  return (
    <GatsbyImage
      fluid={_fluid}
      {...rest}
      fixed={undefined}
      title={title}
      alt={altText}
      imgStyle={{
        ...imgStyle,
        objectFit,
        objectPosition,
        fontFamily: `"object-fit: ${objectFit}; object-position: ${objectPosition}"`,
      }}
    />
  );
}
