import Headroom from 'react-headroom';

import styled from 'utils/emotion';
import media from 'utils/mediaqueries';
import { toPx } from 'utils/styles';

import GridWrap from 'components/common/GridWrap';

export const HeadroomStyled = styled(Headroom)({
  pointerEvents: 'none',

  '&': {
    position: 'absolute',
    zIndex: 1000,
    width: '100%',
  },

  '.headroom--unfixed': {
    width: '100%',
    transform: 'translateY(0)',
    transition: 'padding 150ms ease-in-out',
    padding: '20px 0',

    [media('tabletFluid')]: {
      padding: '20px 24px',
    },
  },

  '.headroom--unpinned': {
    width: '100%',
    position: 'fixed',
    transform: 'translateY(-100%)',

    // [GG-40]
    // Until fixed, we use "transition: 'none'" to avoid the glitch, see:
    // https://github.com/KyleAMathews/react-headroom/issues/154
    // transition: 'transform 300ms ease-in-out',
    transition: 'none',
  },

  '.headroom--pinned': {
    width: '100%',
    position: 'fixed',
    transform: 'translateY(0%)',
    transition: 'transform 150ms ease-in-out',
  },
});

export const HeaderStyled = styled(GridWrap)(
  {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  (props) => ({
    padding: toPx(props.theme.spaceHalf),
    [media('tabletFluid')]: {
      padding: `${toPx(props.theme.space)} ${toPx(
        props.theme.spaceHalf,
      )} ${toPx(props.theme.spaceHalf)} ${toPx(props.theme.spaceHalf)}`,
    },
  }),
);

export const HeaderItemStyled = styled('div')({});
