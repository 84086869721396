import styled from 'utils/emotion';
import media from 'utils/mediaqueries';
import { toPx } from 'utils/styles';

import Link from 'components/common/Link';

export const MenuOverlayStyled = styled('section')(
  {
    position: 'fixed',
    width: '100vw',
    top: 0,
    left: 0,
    zIndex: 5,
    opacity: 0,
    visibility: 'hidden',
    backfaceVisibility: 'hidden',
    transition: 'opacity .2s, visibility 0s .25s',
    height: '100vh', // fallback

    '@supports(height: calc(100vh - var(--vh-offset, 0px)))': {
      height: 'calc(100vh - var(--vh-offset, 0px))',
    },
  },
  (props) =>
    props.menuOpen && {
      opacity: 1,
      visibility: 'visible',
      transition: 'opacity .4s, visibility 0s',
    },
);

export const MenuBgStyled = styled('div')(
  {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    transition: 'background-color .25s',
  },
  (props) =>
    props.menuOpen && {
      backgroundColor: 'rgba(0,0,0,1)',

      [media('tabletFluid')]: {
        backgroundColor: 'rgba(0,0,0,.8)',
      },
    },
);

export const MenuStyled = styled('nav')(
  {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    transition: 'transform .4s ease-in-out',
    height: 'calc(100% - 128px)', // Adjust height depending on header dimensions.

    [media('tabletFluid', 0, 'portrait')]: {
      height: 'calc(100% - 160px)',
    },

    [media(0, 0, 'landscape')]: {
      width: '75%',
      height: '100%',
      right: 0,
    },

    [media('desktop', 0, 'landscape')]: {
      width: '65%',
    },
  },
  (props) => ({
    [media(0, 0, 'landscape')]: {
      borderLeft: `1px solid ${props.theme.colorTertiary}`,
    },
  }),
);

/**
 * Menu main.
 */
export const MainItemsStyled = styled('div')({
  height: 'calc(100% - 120px)',
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  textAlign: 'center',
  backfaceVisibility: 'hidden',

  '@supports(display: grid)': {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 50%)',
    gridAutoRows: 'auto',
  },
});

export const MainItemStyled = styled('div')({
  position: 'relative',
  overflow: 'hidden',
  flexBasis: '50%',
  height: '50%',

  '@supports(display: grid)': {
    height: '100%',
  },
});

/**
 * Menu meta
 */
export const MetaItemsStyled = styled('div')(
  {
    position: 'relative',
    height: '120px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'flex-start',
  },
  (props) => ({
    background: props.theme.menuGradient,

    [media('tabletFluid')]: {
      padding: `0 ${toPx(props.theme.space * 3)} 0 ${toPx(
        props.theme.space * 2,
      )}`,
    },
  }),
);

/**
 * Menu hightlights
 */
export const HighlightItemsStyled = styled('div')({
  [media('tabletFluid')]: {
    marginLeft: 'auto',
  },
});

export const LinkStyled = styled(Link)(
  {
    display: 'inline-block',
    textTransform: 'uppercase',
    fontSize: 21,
    fontWeight: 300,

    backfaceVisibility: 'hidden',
    transition: 'opacity 150ms',
  },
  (props) => ({
    color: props.theme.colorTertiary,
    fontFamily: props.theme.fontSecondary,
    padding: `${toPx(props.theme.space / 3)} ${toPx(props.theme.space)}`,

    [media('tabletFluid')]: {
      padding: `${toPx(props.theme.space / 2)} ${toPx(props.theme.space)}`,
    },

    '&:hover': {
      opacity: 0.8,
    },
  }),
  (props) =>
    props.hightlight && {
      fontWeight: 700,
    },
);
